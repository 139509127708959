import PricingTable from './PricingTable';
import MetaTags from 'react-meta-tags';

export default function Pricing() {
    return (
      <div className="App">
        <MetaTags>
            <title>Prețuri | Vulcanizare ALEX BEST DRIVE - lângă Sibiu, în Cisnădie</title>
            <link rel="canonical" href="https://www.vulcanizare-alexbestdrive.ro/preturi" />
            <meta name="description" content="Află prețurile serviciilor oferite de vulcanizarea ALEX BEST DRIVE - vulcanizare premium la 5 minute de Sibiu, în Cisnădie." />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <meta charset="UTF-8"/>
        </MetaTags>
        <PricingTable />
      </div>
    );
  }
  