import React from 'react'
import { NavLink, Link } from 'react-router-dom'
import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import {
  MenuIcon,
  XIcon,
} from '@heroicons/react/outline'

const navigation = [
    { name: 'Servicii', href: '/servicii' },
    { name: 'Prețuri', href: '/preturi' },
    { name: 'Blog', href: '/blog' },
    { name: 'Contact', href: '/contact' }
  ]

const Navbar = () => {
    return (
        <Popover as="header" className="relative">
        <div className="bg-gray-900 pt-4">
          <nav
            className="relative max-w-7xl mx-auto flex items-center justify-between px-4 sm:px-6 pb-3"
            aria-label="Global"
          >
            <div className="flex items-center flex-1">
              <div className="flex items-center justify-between w-full md:w-auto">
                <NavLink key="homepage" to="/">
                  <img
                    className="h-16 sm:h-14 w-auto"
                    src={require('../assets/vulcanizare-alex-best-drive-logo.png')}
                    alt="Vulcanizare Alex Best Drive Logo"
                  />
                </NavLink>
                <div className="mr-1 flex items-center md:hidden">
                  <Popover.Button className="bg-gray-900 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:bg-gray-800 ring-2 focus-ring-inset ring-white focus:ring-none">
                    <span className="sr-only">Open main menu</span>
                    <MenuIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="hidden space-x-8 md:flex md:ml-10">
                {navigation.map((item) => (
                   <NavLink className="text-base font-medium text-white hover:text-gray-300" key={item.href} to={item.href}> 
                   {item.name} 
                   </NavLink>
                ))}
              </div>
            </div>
            <div className="hidden md:flex md:items-center md:space-x-6 mr-14">
            <a href="https://g.page/r/Ca7GzrPplDp0EB0/review"
                className="text-base font-medium text-white hover:text-gray-300 sm:hover:scale-110"
                 target="_blank"
                 rel="noreferrer">
                  Lasă-ne un review
                </a>
              <Link
                to="/contact"
                className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 sm:hover:scale-110"
              >
                Programează-te
              </Link>
            </div>
          </nav>
        </div>

        <Transition
          as={Fragment}
          enter="duration-150 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel focus className="absolute top-0 inset-x-0 p-2 transition transform origin-top md:hidden z-10">
            <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div className="px-5 pt-4 flex items-center justify-between">
                <div>
                <img
              className="h-14 w-auto"
              src={require('../assets/vulcanizare-alex-best-drive-logo-footer.png')}
              alt="Vulcanizare Alex Best Drive Logo Footer"
            />
                </div>
                <div className="-mr-2">
                  <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-rose-700">
                    <span className="sr-only">Close menu</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="pt-5 pb-6">
                <div className="px-2 space-y-1">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="block px-3 py-2 rounded-md text-base font-medium text-gray-900 hover:bg-gray-50"
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
                <div className="mt-6 px-5">
                  <a
                    href="/contact"
                    className="block text-center w-full py-3 px-4 rounded-md shadow bg-gradient-to-r from-rose-600 to-rose-900 text-white font-medium hover:from-rose-500 hover:to-rose-700"
                  >
                    Programează-te
                  </a>
                </div>
                <div className="mt-3 px-5 text-center">
                <a href="https://g.page/r/Ca7GzrPplDp0EB0/review"
                 className="text-center text-base font-medium text-gray-500 hover:underline"
                 target="_blank"
                 rel="noreferrer">
                  Lasă-ne un review
                </a>
                </div>
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
    )
}

export default Navbar
