import MetaTags from 'react-meta-tags';

export default function Sfaturi() {
  return (
    <div className="relative py-16 bg-white overflow-hidden">
      <MetaTags>
        <title>Sfaturi Îngrijire Anvelope | Vulcanizare ALEX BEST DRIVE</title>
        <link rel="canonical" href="https://www.vulcanizare-alexbestdrive.ro/blog/sfaturi-ingrijire-anvelope/" />
        <meta name="description" content="Cum să îngrijești anvelopele pentru o utilizare de durată? Află cele mai bune metode prin care anvelopele să reziste pentru mai mult timp." />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta charset="UTF-8" />
      </MetaTags>
      <div className="relative px-4 sm:px-6 lg:px-8">
        <div className="text-lg max-w-prose mx-auto">
          <h1>
            <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Sfaturi pentru îngrijirea anvelopelor
            </span>
          </h1>
          <div className="text-center mt-8">
            <p className="text-sm font-medium text-gray-900">
              <p className="hover:underline">
                Alexandru Buraciuc
              </p>
            </p>
            <div className="space-x-1 text-sm text-gray-500">
              <time dateTime='2022-03-16'>16 Martie, 2022</time>
              <span aria-hidden="true">&middot;</span>
              <span>3 min read</span>
            </div>
          </div>
          <p className="mt-8 text-xl text-gray-500 leading-8">
            Vulcanizarea Alex Best Drive își dorește ca toți clienții săi din Sibiu, Cisnădie și împrejurimi, să folosească anvelopele și mașinile lor la performanță maximă. Poate nu știai, dar fiecare producător de anvelope recomandă practici explicite de urmat pentru ca anvelopele să funcționeze în cel mai bun mod.
          </p>
        </div>
        <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
          <p>
            Este știut că anvelopele contribuie mult la întreaga performanță a mașinii. Printr-o întreținere corectă poți să prelungești viața roților, implicit și a mașinii tale. Îți recomandăm următoarele puncte pe care să le ai în vedere când vine vorba de întreținerea anvelopelor:
          </p>
          <ul>
            <li><h3>Echilibrează roțile corect.</h3> Poți vedea dacă roțile sunt dezechilibrate atunci când treci peste gropi sau denivelări. La viteze mari roțile dezechilibrate cauzează vibrații resimțite în volan.</li>
            <li><h3>Verifică presiunea roților pentru a te asigura că acestea sunt umflate corespunzător.</h3> Dacă nu sunt umflate destul, roțile vor produce mai multă căldură. Aceasta duce la uzură neuniformă a anvelopelor. Frânarea sau direcția de mers a mașinii sunt și ele afectate. Dacă roțile sunt umflate corect atunci carnea cauciucurilor va rezista mai mult timp, direcția mașinii va fi îmbunătățită, iar mașina va avea un consum de combustibil mai bun. </li>
            <li><h3>Înlocuiește roțile în set sau în perechi.</h3> Când îți cumperi anvelope noi, îți recomandăm fie să le schimbi pe toate 4, fie o pereche de două anvelope. Cele două roți noi ar trebui instalate în partea din spate a mașinii, iar roțile vechi, parțial folosite, ar trebui refolosite în partea din față a mașinii.</li>
            <li><h3>Depozitează corect anvelopele între sezoane.</h3> Îți recomandăm să depozitezi anvelopele într-un loc special amenajat pentru acest lucru, cum ar fi Hotelul de Anvelope din cadrul vulcanizării Alex Best Drive. Totuși, dacă nu poți face asta, depozitează anvelopele într-un loc curat, ferit de lumina soarelui și cu umiditate scăzută.</li>
            <li><h3>Inspectează regulat calitatea anvelopei.</h3> Durează doar câteva minute pe lună să te uiți care este starea anvelopelor tale. Verifică dacă au umflături, dacă pierd aer puțin câte puțin, dacă au crăpături (care nu pierd aer) sau țin pietre.</li>
            <li><h3>Schimbă roțile între ele după un calendar recomandat de producător.</h3> Fiecare producător recomandă rotirea roților între ele după un anumit calendar. Verifică manualul producătorului și respectă-l. Dacă nu ești sigur de acest calendar de rotire, dă-ne un telefon și te putem ajuta.</li>
          </ul>
          <p>
            Ia aceste sfaturi în considerare pentru o mai bună funcționare a mașinii tale. Fără anvelope corecte nu o să ai confortul și siguranța necesară.
          </p>
        </div>
      </div>
    </div>
  )
}
