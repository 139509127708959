import MetaTags from 'react-meta-tags';
import { Link } from "react-router-dom";

export default function LocatieExacta() {
    return (
        <div className="relative py-16 bg-white overflow-hidden">
            <MetaTags>
                <title>Cum ajungi la Vulcanizarea ALEX BEST DRIVE din Cisnadie | Directii catre Vulcanizarea ALEX BEST DRIVE</title>
                <link rel="canonical" href="https://www.vulcanizare-alexbestdrive.ro/blog/locatie-vulcanizare-cisnadie/" />
                <meta name="description" content="Cum ajungi la Vulcanizarea ALEX BEST DRIVE din Cisnadie? Află rutele prin care ajungi la vulcanizarea din Cisnadie, dacă ești locuitor din Cisnadie, Sibiu, Selimbar, Cristian sau Cisnadioara" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <meta charSet="UTF-8" />
            </MetaTags>
            <div className="relative px-4 sm:px-6 lg:px-8">
                <div className="text-lg max-w-prose mx-auto">
                    <h1>
                        <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                            Cum ajungi la vulcanizarea ALEX BEST DRIVE din Cisnadie?
                        </span>
                    </h1>
                    <div className="text-center mt-8">
                        <div className="text-sm font-medium text-gray-900">
                            <p className="hover:underline">
                                Alexandru Buraciuc
                            </p>
                        </div>
                        <div className="space-x-1 text-sm text-gray-500">
                            <time dateTime='2022-05-03'>3 Mai, 2022</time>
                            <span aria-hidden="true">&middot;</span>
                            <span>5 min read</span>
                        </div>
                    </div>
                    <p className="mt-8 text-xl text-gray-500 leading-8">
                        În acest articol îți vom prezenta locația exactă unde se află vulcanizarea ALEX BEST DRIVE din Cisnadie, cât și cele mai rapide trasee pe care le poți urma pentru a ajunge la noi în funcție de zona în care locuiești.
                    </p>
                </div>
                <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
                    <p>
                        Vulcanizarea ALEX BEST DRIVE este localizată la intrarea în orașul Cisnadie, dinspre drumul european E68, lângă fosta moară Panalim (strada Șelimbărului, nr.32) - vezi pe
                        <a href='https://goo.gl/maps/8owE7e6DtiYSaAWb6'> Google Maps </a>. Desigur, sunt mai multe căi de acces pe care le vom detalia în secțiunile următoare.
                    </p>
                    <p>
                        Când vei ajunge în locația noastră vei beneficia de o parcare largă, fără probleme de access și asfaltată corespunzător. Pe lângă aceasta, în funcție de sezon, vei avea parte de timpi de așteptare scăzuți și o cafea bună care să te ajute cu trecerea acestora.
                    </p>
                    <div className="relative w-fit mx-auto">
                        <img
                            className="w-full h-full object-fit"
                            src={require('../../assets/vulcanizare-sibiu-alexbestdrive-colaj.webp')}
                            alt="Colaj imagini vulcanizare alex best drive cisnadie"
                        />
                    </div>
                    <h3>Vom prezenta câteva rute pe care le poți urma dacă ești locuitor al următoarelor zone:</h3>

                    <div><h2>Cisnadie</h2> <p>Dacă ești locuitor al orașului Cisnădie, poți ajunge în mai puțin de 3 minute la noi. Dacă luăm ca reper sensul giratoriu de lângă magazinul Penny, poți merge pe strada Șelimbărului timp de 2-3 minute către zona Șelimbăr, iar pe noi ne găsești pe partea dreaptă, după o trecere de pietoni, la numărul 32. <a href='https://www.google.com/maps/dir/Cisn%C4%83die/Vulcanizare+Alex+Best+Drive,+Strada+%C8%98elimb%C4%83rului+32,+Cisn%C4%83die+555300/@45.7185717,24.1521705,15z/data=!3m1!4b1!4m14!4m13!1m5!1m1!1s0x474c5c1cbd9cbf13:0x1f92a48f97d62e6f!2m2!1d24.1548523!2d45.7139464!1m5!1m1!1s0x474c5d89ff26b4a1:0x743a94e9b3cec6ae!2m2!1d24.1669982!2d45.7232203!3e0' target="_blank" rel="noreferrer">Google Maps</a> Cisnădie - Vulcanizare ALEX BEST DRIVE</p></div>
                    <div><h2>Sibiu</h2><p> Dacă locuiești în orașul Sibiu, poți ajunge la Vulcanizare ALEX BEST DRIVE pe diferite trasee, însă merită să verifici care dintre ele este cel mai rapid în funcție de ziua, ora și condițiile de trafic. Traseul cel mai scurt este să urmezi strada Grigore Ionescu, prin cartierul Arhitecților, după care Strada Sibiului din Cisnadie, iar mai apoi Strada Șelimbărului, noi fiind localizați la numărul 32. Nu ar trebui să îți ia mai mult de 15 minute în condiții de trafic normal. Desigur, poate consideri că o vulcanizare din Sibiu îți este mai la îndemână, însă te asigurăm ca nu vei găsi aceleași servicii de calitate sau timpi de așteptare scăzuți. Pentru un timp de așteptare zero te sfătuim să te programezi telefonic sau <Link to='/contact'>online</Link>. <a href='https://www.google.com/maps/dir/Sibiu/Vulcanizare+Alex+Best+Drive,+Strada+%C8%98elimb%C4%83rului+32,+Cisn%C4%83die+555300/@45.7559248,24.1291871,13z/data=!3m1!4b1!4m14!4m13!1m5!1m1!1s0x474c6788fd2c1cd5:0x3ade9d214e3390b4!2m2!1d24.1212945!2d45.7935607!1m5!1m1!1s0x474c5d89ff26b4a1:0x743a94e9b3cec6ae!2m2!1d24.1669982!2d45.7232203!3e0' target="_blank" rel="noreferrer">Google Maps</a> Sibiu - Vulcanizare ALEX BEST DRIVE</p></div>
                    <div><h2>Șelimbăr</h2><p> Pentru cei care locuiesc în Șelimbăr este la fel de simplu să ajungă la vulcanizarea noastră. În aproximativ 5 minute șelimbărenii pot ajunge in Cisnadie, la vulcanizarea ALEX BEST DRIVE. Trebuie doar să urmeze E68 și apoi strada Șelimbărului. Vă așteptăm la numărul 32, înainte de o trecere de pietoni, pe partea stângă. <a href='https://www.google.com/maps/dir/%C8%98elimb%C4%83r/Vulcanizare+Alex+Best+Drive,+Strada+%C8%98elimb%C4%83rului+32,+Cisn%C4%83die+555300/@45.7464576,24.1484879,13z/data=!3m1!4b1!4m14!4m13!1m5!1m1!1s0x474c5df200868dab:0xdc34ace26934db87!2m2!1d24.1804581!2d45.7684434!1m5!1m1!1s0x474c5d89ff26b4a1:0x743a94e9b3cec6ae!2m2!1d24.1669982!2d45.7232203!3e0' target="_blank" rel="noreferrer">Google Maps</a> Șelimbăr - Vulcanizare ALEX BEST DRIVE </p></div>
                    <div><h2>Cristian</h2><p> Pentru locuitorii din Cristian aceștia pot urma rutele pe care și sibienii le folosesc pentru a ajunge la noi. În aproximativ 20 de minute puteți ajunge din Cristian la vulcanizarea noastră din Cisnădie. <a href='https://www.google.com/maps/dir/Cristian/Vulcanizare+Alex+Best+Drive,+Strada+%C8%98elimb%C4%83rului+32,+Cisn%C4%83die+555300/@45.7724533,24.0474003,12z/data=!3m1!4b1!4m14!4m13!1m5!1m1!1s0x474c425bd4774549:0x9b5e6f1ae4005c81!2m2!1d24.0294992!2d45.7821141!1m5!1m1!1s0x474c5d89ff26b4a1:0x743a94e9b3cec6ae!2m2!1d24.1669982!2d45.7232203!3e0' target="_blank" rel="noreferrer">Google Maps</a> Cristian - Vulcanizare ALEX BEST DRIVE</p></div>
                    <div><h2>Cisnădioara</h2><p> Cisnadioara fiind la doi pași de Cisnădie nu va dura mai mult de 10 minute să ajungi la noi. Parcurgi orașul Cisnădie, la sensul giratoriu de lângă Penny mergi pe strada Șelimbărului, iar la numărul 32, pe partea dreaptă te așteptăm noi. <a href='https://www.google.com/maps/dir/Cisn%C4%83dioara/Vulcanizare+Alex+Best+Drive,+Strada+%C8%98elimb%C4%83rului+32,+Cisn%C4%83die+555300/@45.7135578,24.1202694,14z/data=!3m1!4b1!4m14!4m13!1m5!1m1!1s0x474c5b889ce7d101:0xdc591cc3591ce291!2m2!1d24.1085598!2d45.7079343!1m5!1m1!1s0x474c5d89ff26b4a1:0x743a94e9b3cec6ae!2m2!1d24.1669982!2d45.7232203!3e0' target="_blank" rel="noreferrer">Google Maps</a> Cisnadioara - Vulcanizare ALEX BEST DRIVE</p></div>
                    <div className="relative w-fit mx-auto">
                        <img
                            className="w-full h-full object-fit"
                            src={require('../../assets/vulcanizare-cisnadie-locatie.jpeg')}
                            alt="Exterior vulcanizare alex best drive cisnadie"
                        />
                    </div>
                    <p>
                        Pentru orice întrebări sau nelămuriri ne poți contacta la telefon <a href="tel:+40757132016">0757132016</a> sau ne poți lăsa un mesaj <Link to='/contact'>online</Link> și te sunăm noi.

                    </p>
                </div>
            </div>
        </div>
    )
}
