import { useParams } from 'react-router-dom';
import Sfaturi from './Blogs/Sfaturi';
import LocatieExacta from './Blogs/LocatieExacta';


const BlogDetails = () => {
    const { id } = useParams();
    return ( 
        <div className="blog-details">
            {id === 'sfaturi-ingrijire-anvelope' && <Sfaturi/>}
            {id === 'locatie-vulcanizare-cisnadie' && <LocatieExacta/>}
        </div>
     );
}

export default BlogDetails;