import { React, useState, Fragment } from "react";
import Select from "./Select/Select";
import { CheckCircleIcon, CurrencyDollarIcon } from '@heroicons/react/solid'
import { preturi_per_serviciu, tipuri_jante, tipuri_de_vehicul, tipuri_servicii_aux, servicii_auxiliare } from "./Data/constants"


const includedFeatures = [
  'Schimb anvelope',
  'Echilibrare anvelope',
  'Hotel anvelope',
  'Curățare butuc roată',
  'Reparație anvelope',
  'Îndreptare jante',
  'Verificare fluide',
  'Verificare placuțe',
]

const PricingTable = () => {

  const temp = preturi_per_serviciu.find((ext) => ext.value);

  const [currentExtension, setCurrentExtension] = useState(temp);

  return (
    <div>
      <div>
        <div className="lg:bg-gray-100 sm:flex sm:flex-col sm:align-center align-center pb-6 px-0 mx-auto justify-center">
          <h1 className="text-3xl sm:text-4xl lg:text-5xl pt-10 font-extrabold text-gray-900 text-center">Tarife Aplicate</h1>
          <h2 className="mt-5 text-xl text-gray-500 text-center px-4" >
            Dacă nu dorești un abonament anual, regăsești mai jos tarifele pentru fiecare serviciu oferit.
          </h2>
          <h3 className="mt-1 text-xl text-gray-500 text-center px-4">
            Pentru unele servicii de vulcanizare se aplica reduceri
            <CurrencyDollarIcon className="ml-auto h-6 w-6 text-red-500 inline-block" aria-hidden="true" />
          </h3>
        </div>
        <div className="relative">
          <div className="absolute inset-0 h-1/2 lg:bg-gray-100" />
          <div className="relative max-w-7xl rounded-3xl sm:flex sm:flex-row sm:align-center pt-8 mx-auto justify-center lg:mb-6">

            {/* xs to lg */}
            <div className="lg:hidden max-w-2xl mx-auto space-y-6">
              <div className="block">
                <div className="pl-6 pt-2 mr-0 pr-0 text-lg sm:text-sm font-bold sm:font-large text-black-800 text-left inline-block">Mărimea jantei:</div>
                <div className="w-30 pl-2 sm:pl-6 inline-block">
                  <Select
                    options={preturi_per_serviciu}
                    selectedOption={currentExtension}
                    handleChange={(value) => {
                      setCurrentExtension(preturi_per_serviciu.find((ext) => ext.id === value));
                    }}
                  />
                </div>
              </div>
              {tipuri_de_vehicul.map((section) => (
                <table key={section.name} className="w-full">
                  <thead>
                    <tr>
                      <th className="bg-gray-50 border-t border-gray-200 py-3 px-4 text-md align-middle font-medium text-gray-900 text-left">
                        {section.name}
                      </th>
                      <th className="bg-gray-50 border-t border-gray-200 py-3 px-2 text-sm font-medium text-gray-900 text-right">
                        Jante de Aluminiu
                      </th>
                      <th className="bg-gray-50 border-t border-gray-200 py-3 px-2 text-sm font-medium text-gray-900 text-right">
                        Jante de Tablă
                      </th>
                    </tr>
                    <tr>
                      <th className="sr-only" scope="col">
                        Feature
                      </th>
                      <th className="sr-only" scope="col">
                        Included
                      </th>
                      <th className="sr-only" scope="col">
                        Included
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {section.features.map((feature) => (
                      <tr key={feature.name} className="border-t border-gray-200">
                        <th className="py-5 pl-4 pr-1 text-sm font-normal text-gray-500 text-left" scope="row">
                          {feature.idd === 'total' ? (
                            <span>{feature.name} <CurrencyDollarIcon className="ml-auto h-6 w-6 text-red-500 inline-block" aria-hidden="true" /></span>
                          ) :
                            (
                              feature.name
                            )
                          }
                        </th>
                        {tipuri_jante.map(
                          (tier) => (
                            <td className="py-5 pr-4">
                              <span className="block text-sm text-gray-700 text-right">
                                {currentExtension.value[section.idd][feature.idd][tier.name]}
                              </span>
                            </td>
                          )
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              ))}
              <div className="lg:hidden bg-white max-w-2xl mx-auto sm:space-y-6">
                <table className="h-px table-fixed w-full">
                  <thead className="px-0 mx-0 w-full">
                    <tr>
                      <th className="px-6 text-md leading-6 font-large text-gray-900 text-left sm:pt-7 sm:pb-7">
                      </th>
                    </tr>
                  </thead>
                  <tbody className="border-t border-gray-200 divide-y divide-gray-200 w-full">
                    {servicii_auxiliare.map((section) => (
                      <Fragment key={section.name}>
                        <tr className="bg-gray-50">
                          <th
                            className="py-3 pl-6 text-lg font-medium text-black-800 text-left w-full" scope="row"
                          >
                            {section.name}
                          </th>
                          <th className="bg-gray-50 text-right px-4">
                            Pret
                          </th>
                        </tr>
                        {section.features.map((feature) => (
                          <tr key={feature.name}>
                            <th className="py-5 px-6 text-sm font-normal text-gray-500 text-left" scope="row">
                              {feature.idd === 'total' ? (
                                <span>{feature.name} <CurrencyDollarIcon className="ml-auto h-6 w-6 text-red-500 inline-block" aria-hidden="true" /></span>
                              ) :
                                (
                                  feature.name
                                )
                              }
                              <span>
                          {feature.idd === 'roata_petic' ?
                                  <span className="inline-block text-gray-500 text-xs text-left px-0 mx-0"> *(de)montat/echilibrat extracost </span>
                                  :
                                  null
                                }
                            </span>
                            </th>
                            {tipuri_servicii_aux.map((tier) => (
                              <td key={tier.name} className="py-5 pr-4">
                                <span className="block text-sm text-right text-gray-700">
                                  {feature.idd === 'roata_petic' ?
                                    currentExtension.value['petic']['mcx_10']
                                    :
                                    feature.tiers.Essential
                                  }
                                </span>
                              </td>
                            ))}
                          </tr>
                        ))}
                      </Fragment>
                    ))}
                  </tbody>
                </table>
                <div className="mt-2 sm:mt-6">
                  <div className="rounded-md px-3 mb-6">
                    <a
                      href="/contact"
                      className="flex items-center justify-center bg-gradient-to-r from-red-600 to-red-700 bg-origin-border px-5 py-3 mx-auto border text-base font-medium rounded-md shadow-sm text-white hover:from-red-500 hover:to-red-600"
                    >
                      Programează-te
                    </a>
                  </div>
                </div>
              </div>
            </div>

            {/* lg+ */}
            <div className="hidden bg-white lg:inline-block h-fit border border-gray-200 rounded-lg shadow-lg divide-y justify-center">
              <table className="h-px w-3/2">
                <thead>
                  <tr>
                    <th className="w-60">
                      <div className="pl-6 pt-2 mr-0 pr-0 text-sm font-large text-black-800 text-left">Alege mărimea jantei</div>
                      <div className="w-42 pb-2 pl-6">
                        <Select
                          options={preturi_per_serviciu}
                          selectedOption={currentExtension}
                          handleChange={(value) => {
                            setCurrentExtension(preturi_per_serviciu.find((ext) => ext.id === value));
                          }}
                        />
                      </div>
                    </th>
                    {tipuri_jante.map((tier) => (
                      <th
                        key={tier.name}
                        className="w-1/4 px-6 text-lg leading-6 font-large text-gray-900"
                        scope="col"
                      >
                        {tier.formatedName}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="border-t border-gray-200 divide-y divide-gray-200">
                  {tipuri_de_vehicul.map((section) => (
                    <Fragment key={section.name}>
                      <tr>
                        <th
                          className="bg-gray-200 py-3 pl-6 text-lg font-medium text-black-800 text-left"
                          colSpan={3}
                          scope="colgroup"
                        >
                          {section.name}
                        </th>
                      </tr>
                      {section.features.map((feature) => (
                        <tr key={feature.name}>
                          <th className="py-5 px-6 text-sm font-normal text-gray-500 text-left" scope="row">
                            {feature.idd === 'total' || feature.idd === 'total6' ? (
                              <span>{feature.name} <CurrencyDollarIcon className="ml-auto h-6 w-6 text-red-500 inline-block" aria-hidden="true" /></span>
                            ) :
                              (
                                feature.name
                              )
                            }
                          </th>
                          {tipuri_jante.map((tier) => (
                            <td key={tier.name} className="py-5 px-6">
                              <span className="block text-sm text-center text-gray-700">
                                {currentExtension.value[section.idd][feature.idd][tier.name]}
                              </span>
                            </td>
                          ))}
                        </tr>
                      ))}
                    </Fragment>
                  ))}
                </tbody>
              </table>
              <div className="hidden lg:block">
          <div className="rounded-md px-3 py-4 max-w-3xl align-center mx-auto">
            <a
              href="/contact"
              className="flex items-center justify-center bg-gradient-to-r from-red-600 to-red-700 bg-origin-border px-5 py-3 mx-auto border text-base font-medium rounded-md shadow-sm text-white hover:from-red-500 hover:to-red-600"
            >
              Programează-te
            </a>
          </div>
        </div>
            </div>
            <div className="hidden bg-white lg:inline-block ml-5 border border-gray-200 rounded-lg shadow-lg divide-y divide-gray-200 mb-auto">
              <table className="h-px table-fixed">
                <thead className="px-0 mx-0">
                  <tr>
                    <th className="w-3/4 px-6 text-lg leading-6 font-large text-gray-900 text-left pt-7 pb-7">
                      ALTE SERVICII
                    </th>
                    <th>
                    </th>
                    {/* {tipuri_servicii_aux.map((tier) => (
                    <th
                      key={tier.name}
                      className="w-1/4 pb-2 pt-4 px-6 text-lg leading-6 font-medium text-gray-900 text-left"
                      scope="col"
                    >
                    </th>
                  ))} */}
                  </tr>
                </thead>
                <tbody className="border-t border-gray-200 divide-y divide-gray-200">
                  {servicii_auxiliare.map((section) => (
                    <Fragment key={section.name}>
                      <tr>
                        <th
                          className="bg-gray-200 py-3 pl-6 text-lg font-medium text-black-800 text-left"
                          colSpan={4}
                          scope="colgroup"
                        >
                          {section.name}
                        </th>
                      </tr>
                      {section.features.map((feature) => (
                        <tr key={feature.name}>
                          <th className="py-5 px-6 text-sm font-normal text-gray-500 text-left" scope="row">
                          {feature.name}
                          <span>
                          {feature.idd === 'roata_petic' ?
                                  <span className="inline-block text-gray-500 text-xs text-left px-0 mx-0"> *(de)montat/echilibrat extracost </span>
                                  :
                                  null
                                }
                            </span>
                          </th>
                          {tipuri_servicii_aux.map((tier) => (
                            <td key={tier.name} className="py-5 px-auto">
                              <span className="block text-sm text-gray-700">
                                {feature.idd === 'roata_petic' ?
                                  currentExtension.value['petic']['mcx_10']
                                  :
                                  feature.tiers.Essential
                                }
                              </span>
                            </td>
                            
                          ))}
                        </tr>
                      ))}
                    </Fragment>
                  ))}
                </tbody>
              </table>
              <div className="rounded-md px-3 py-4 max-w-3xl align-center mx-auto">
            <a
              href="/contact"
              className="flex items-center justify-center bg-gradient-to-r from-red-600 to-red-700 bg-origin-border px-5 py-3 mx-auto border text-base font-medium rounded-md shadow-sm text-white hover:from-red-500 hover:to-red-600"
            >
              Programează-te
            </a>
          </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-gray-100">
        <div className="pt-12 sm:pt-16 lg:pt-20">
          <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center">
              <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl lg:text-5xl">Abonament vulcanizare anual</h2>
              <p className="mt-4 text-xl text-gray-600">
                Sau poți să stai fără griji tot anul. Ai prioritate, reduceri și servicii extra incluse!
              </p>
            </div>
          </div>
        </div>

        <div className="mt-4 bg-white pb-16 sm:mt-12 sm:pb-20 lg:pb-14">
          <div className="relative">
            <div className="absolute inset-0 h-1/2 bg-gray-100" />
            <div className="relative max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="max-w-lg mx-auto rounded-lg shadow-lg overflow-hidden lg:max-w-none lg:flex">
                <div className="flex-1 bg-white px-6 py-8 lg:p-12">
                  <h3 className="text-2xl font-extrabold text-gray-900 sm:text-3xl">Abonament Anual</h3>
                  <p className="mt-6 text-base text-gray-500">
                    Acest abonament include tot ce au nevoie anvelopele tale pentru întreg anul, fie iarnă, fie vară! Pur și simplu plătești un singur preț dar ai TOTUL inclus timp de un an!
                  </p>
                  <div className="mt-8">
                    <div className="flex items-center">
                      <h4 className="flex-shrink-0 pr-4 bg-white text-sm tracking-wider font-semibold uppercase text-red-600">
                        Ce include?
                      </h4>
                      <div className="flex-1 border-t-2 border-gray-200" />
                    </div>
                    <ul className="mt-8 space-y-5 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-5">
                      {includedFeatures.map((feature) => (
                        <li key={feature} className="flex items-start lg:col-span-1">
                          <div className="flex-shrink-0">
                            <CheckCircleIcon className="h-5 w-5 text-red-600" aria-hidden="true" />
                          </div>
                          <p className="ml-3 text-sm text-gray-700">{feature}</p>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="py-8 px-6 text-center bg-gray-50 lg:flex-shrink-0 lg:flex lg:flex-col lg:justify-center lg:p-12">
                  <p className="text-lg leading-6 font-medium text-gray-900">Un an întreg, fără griji</p>
                  <div className="mt-4 flex items-center justify-center text-5xl font-extrabold text-gray-900">
                    <span>500</span>
                    <span className="ml-3 text-xl font-medium text-gray-500">LEI</span>
                  </div>
                  <p className="mt-4 text-sm">
                    <a href="/contact" className="font-medium text-gray-500 underline">
                      Vezi mai multe detalii despre abonament
                    </a>
                  </p>
                  <div className="mt-6">
                    <div className="rounded-md shadow">
                      <a
                        href="/contact"
                        className="flex items-center justify-center bg-gradient-to-r from-red-600 to-red-700 bg-origin-border px-5 py-3 mx-auto border text-base font-medium rounded-md shadow-sm text-white hover:from-red-500 hover:to-red-600"
                      >
                        Contactează-ne
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )

};

export default PricingTable;
