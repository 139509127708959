import { Link } from "react-router-dom"
import MetaTags from 'react-meta-tags';

const NotFound = () => {
    return (
        <div className="not-found">
            <MetaTags>
                <title>Servicii vulcanizare | Vulcanizare ALEX BEST DRIVE din Sibiu, Cisnădie</title>
                <link rel="canonical" href="https://www.vulcanizare-alexbestdrive.ro/404" />
                <meta name="description" content="Vulcanizare ALEX BEST DRIVE: la 5 minute de Sibiu, în Cisnădie. Oferim servicii de vulcanizare, reparație, hotel anvelope și schimb ulei." />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <meta charset="UTF-8" />
            </MetaTags>
            <h2>Eroare 404</h2>
            <p>Pagina nu a putut fi gasita!</p>
            <Link to='/'>Inapoi la pagina principala.</Link>
        </div>
    );
}

export default NotFound;