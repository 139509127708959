
export const preturi_per_serviciu = [
  {
    id: "1",
    label: "≤16 inch",
    value: {
      autov: {
        demontat: {
          otel: '13 LEI',
          aluminiu: '15 LEI'
        },
        dejantat: {
          otel: '6 LEI',
          aluminiu: '6 LEI'
        },
        echilibrat: {
          otel: '10 LEI',
          aluminiu: '10 LEI'
        },
        total: {
          otel: '120 LEI',
          aluminiu: '140 LEI'
        },
      },
      suv: {
        demontat: {
          otel: '15 LEI',
          aluminiu: '15 LEI'
        },
        dejantat: {
          otel: '8 LEI',
          aluminiu: '8 LEI'
        },
        echilibrat: {
          otel: '12 LEI',
          aluminiu: '15 LEI'
        },
        total: {
          otel: '160 LEI',
          aluminiu: '176 LEI'
        },
      },
      autoutilitara: {
        demontat: {
          otel: '15 LEI',
          aluminiu: '15 LEI'
        },
        dejantat: {
          otel: '8 LEI',
          aluminiu: '8 LEI'
        },
        echilibrat: {
          otel: '12 LEI',
          aluminiu: '15 LEI'
        },
        total: {
          otel: '160 LEI',
          aluminiu: '176 LEI'
        },
        total6: {
          otel: '240 LEI',
          aluminiu: '260 LEI'
        },
      },
      petic: {
        mcx_10: '35 LEI'
      },
    }
  },
  {
    id: "2",
    label: "17 inch",
    value: {
      autov: {
        demontat: {
          otel: '13 LEI',
          aluminiu: '15 LEI'
        },
        dejantat: {
          otel: '6 LEI',
          aluminiu: '8 LEI'
        },
        echilibrat: {
          otel: '10 LEI',
          aluminiu: '11 LEI'
        },
        total: {
          otel: '120 LEI',
          aluminiu: '160 LEI'
        },
      },
      suv: {
        demontat: {
          otel: '15 LEI',
          aluminiu: '15 LEI'
        },
        dejantat: {
          otel: '8 LEI',
          aluminiu: '10 LEI'
        },
        echilibrat: {
          otel: '12 LEI',
          aluminiu: '20 LEI'
        },
        total: {
          otel: '160 LEI',
          aluminiu: '208 LEI'
        },
      },
      autoutilitara: {
        demontat: {
          otel: '15 LEI',
          aluminiu: '15 LEI'
        },
        dejantat: {
          otel: '8 LEI',
          aluminiu: '10 LEI'
        },
        echilibrat: {
          otel: '12 LEI',
          aluminiu: '20 LEI'
        },
        total: {
          otel: '160 LEI',
          aluminiu: '208 LEI'
        },
        total6: {
          otel: '240 LEI',
          aluminiu: '312 LEI'
        },
      },
      petic: {
        mcx_10: '35 LEI'
      },
    }
  },
  {
    id: "3",
    label: "18 inch",
    value: {
      autov: {
        demontat: {
          otel: '13 LEI',
          aluminiu: '18 LEI'
        },
        dejantat: {
          otel: '6 LEI',
          aluminiu: '10 LEI'
        },
        echilibrat: {
          otel: '10 LEI',
          aluminiu: '15 LEI'
        },
        total: {
          otel: '120 LEI',
          aluminiu: '200 LEI'
        },
      },
      suv: {
        demontat: {
          otel: '15 LEI',
          aluminiu: '15 LEI'
        },
        dejantat: {
          otel: '8 LEI',
          aluminiu: '10 LEI'
        },
        echilibrat: {
          otel: '12 LEI',
          aluminiu: '20 LEI'
        },
        total: {
          otel: '160 LEI',
          aluminiu: '208 LEI'
        },
      },
      autoutilitara: {
        demontat: {
          otel: '15 LEI',
          aluminiu: '15 LEI'
        },
        dejantat: {
          otel: '8 LEI',
          aluminiu: '10 LEI'
        },
        echilibrat: {
          otel: '12 LEI',
          aluminiu: '20 LEI'
        },
        total: {
          otel: '160 LEI',
          aluminiu: '208 LEI'
        },
        total6: {
          otel: '240 LEI',
          aluminiu: '312 LEI'
        },
      },
      petic: {
        mcx_10: '40 LEI'
      },
    }
  },
  {
    id: "4",
    label: "19 inch",
    value: {
      autov: {
        demontat: {
          otel: '13 LEI',
          aluminiu: '18 LEI'
        },
        dejantat: {
          otel: '6 LEI',
          aluminiu: '10 LEI'
        },
        echilibrat: {
          otel: '10 LEI',
          aluminiu: '17 LEI'
        },
        total: {
          otel: '120 LEI',
          aluminiu: '208 LEI'
        },
      },
      suv: {
        demontat: {
          otel: '15 LEI',
          aluminiu: '15 LEI'
        },
        dejantat: {
          otel: '8 LEI',
          aluminiu: '10 LEI'
        },
        echilibrat: {
          otel: '12 LEI',
          aluminiu: '20 LEI'
        },
        total: {
          otel: '160 LEI',
          aluminiu: '208 LEI'
        },
      },
      autoutilitara: {
        demontat: {
          otel: '15 LEI',
          aluminiu: '15 LEI'
        },
        dejantat: {
          otel: '8 LEI',
          aluminiu: '10 LEI'
        },
        echilibrat: {
          otel: '12 LEI',
          aluminiu: '20 LEI'
        },
        total: {
          otel: '160 LEI',
          aluminiu: '208 LEI'
        },
        total6: {
          otel: '240 LEI',
          aluminiu: '312 LEI'
        },
      },
      petic: {
        mcx_10: '50 LEI'
      },
    }
  },
  {
    id: "5",
    label: "20 inch",
    value: {
      autov: {
        demontat: {
          otel: '13 LEI',
          aluminiu: '18 LEI'
        },
        dejantat: {
          otel: '6 LEI',
          aluminiu: '10 LEI'
        },
        echilibrat: {
          otel: '10 LEI',
          aluminiu: '20 LEI'
        },
        total: {
          otel: '120 LEI',
          aluminiu: '224 LEI'
        },
      },
      suv: {
        demontat: {
          otel: '15 LEI',
          aluminiu: '18 LEI'
        },
        dejantat: {
          otel: '8 LEI',
          aluminiu: '10 LEI'
        },
        echilibrat: {
          otel: '12 LEI',
          aluminiu: '20 LEI'
        },
        total: {
          otel: '160 LEI',
          aluminiu: '224 LEI'
        },
      },
      autoutilitara: {
        demontat: {
          otel: '15 LEI',
          aluminiu: '18 LEI'
        },
        dejantat: {
          otel: '8 LEI',
          aluminiu: '10 LEI'
        },
        echilibrat: {
          otel: '12 LEI',
          aluminiu: '20 LEI'
        },
        total: {
          otel: '160 LEI',
          aluminiu: '224 LEI'
        },
        total6: {
          otel: '240 LEI',
          aluminiu: '336 LEI'
        },
      },
      petic: {
        mcx_10: '55 LEI'
      },
    }
  },
  {
    id: "6",
    label: "≥21 inch",
    value: {
      autov: {
        demontat: {
          otel: '13 LEI',
          aluminiu: '23 LEI'
        },
        dejantat: {
          otel: '6 LEI',
          aluminiu: '11 LEI'
        },
        echilibrat: {
          otel: '10 LEI',
          aluminiu: '30 LEI'
        },
        total: {
          otel: '120 LEI',
          aluminiu: '280 LEI'
        },
      },
      suv: {
        demontat: {
          otel: '15 LEI',
          aluminiu: '23 LEI'
        },
        dejantat: {
          otel: '8 LEI',
          aluminiu: '11 LEI'
        },
        echilibrat: {
          otel: '12 LEI',
          aluminiu: '30 LEI'
        },
        total: {
          otel: '160 LEI',
          aluminiu: '280 LEI'
        },
      },
      autoutilitara: {
        demontat: {
          otel: '15 LEI',
          aluminiu: '15 LEI'
        },
        dejantat: {
          otel: '8 LEI',
          aluminiu: '10 LEI'
        },
        echilibrat: {
          otel: '12 LEI',
          aluminiu: '20 LEI'
        },
        total: {
          otel: '160 LEI',
          aluminiu: '280 LEI'
        },
        total6: {
          otel: '240 LEI',
          aluminiu: '420 LEI'
        },
      },
      petic: {
        mcx_10: '55 LEI'
      },
    }
  },
];

export const tipuri_jante = [
  {
    name: 'aluminiu',
    formatedName: 'ALUMINIU',
    priceMonthly: 9
  },
  {
    name: 'otel',
    formatedName: 'OȚEL',
    priceMonthly: 59,
  },
]

export const tipuri_de_vehicul = [
  {
    name: 'Autoturism',
    idd: 'autov',
    features: [
      { idd: 'demontat', name: '(De)montat roată', tiers: { aluminiu: "2", otel: "7" } },
      { idd: 'dejantat', name: '(De)jantat anvelopă', tiers: { aluminiu: '5 RON', otel: true } },
      { idd: 'echilibrat', name: 'Echilibrat roată', tiers: { aluminiu: '5 RON', otel: true } },
      { idd: 'total', name: 'TOTAL 4 Roți', tiers: { aluminiu: '96 RON', otel: 'Up to 50 users' } },
    ],
  },
  {
    name: 'SUV',
    idd: 'suv',
    features: [
      { idd: 'demontat', name: '(De)montat roată', tiers: { aluminiu: "2", otel: "7" } },
      { idd: 'dejantat', name: '(De)jantat anvelopă', tiers: { aluminiu: "2", otel: "7" } },
      { idd: 'echilibrat', name: 'Echilibrat roată', tiers: { aluminiu: "2", otel: "7" } },
      { idd: 'total', name: 'TOTAL 4 Roți', tiers: { aluminiu: "2", otel: "7" } },
    ],
  },
  {
    name: 'Autoutilitară',
    idd: 'autoutilitara',
    features: [
      { idd: 'demontat', name: '(De)montat roată', tiers: { aluminiu: "2", otel: "7" } },
      { idd: 'dejantat', name: '(De)jantat anvelopă', tiers: { aluminiu: "2", otel: "7" } },
      { idd: 'echilibrat', name: 'Echilibrat roată', tiers: { aluminiu: "2", otel: "7" } },
      { idd: 'total', name: 'TOTAL 4 Roți', tiers: { aluminiu: "2", otel: "7" } },
      { idd: 'total6', name: 'TOTAL 6 Roți', tiers: { aluminiu: "2", otel: "7" } },
    ],
  }
]

export const tipuri_servicii_aux = [
  { name: 'Pret', priceMonthly: 9 },
]

export const servicii_auxiliare = [
  {
    name: 'Motocicletă',
    features: [
      { idd: 'dejantat', name: '(De)jantat anvelopă', tiers: { Essential: '15 LEI' } },
      { idd: 'demontat', name: '(De)montat cameră', tiers: { Essential: '5 LEI' } },
      { idd: 'echilibrat', name: 'Echilibrat roată', tiers: { Essential: '20 LEI' } },
      { idd: 'total', name: 'TOTAL 2 Roți', tiers: { Essential: '100 LEI' } },
    ],
  },
  {
    name: 'Reparație Roată',
    features: [
      { idd: 'roata_snur', name: 'Cu Șnur', tiers: { Essential: '8 LEI' } },
      { idd: 'roata_petic', name: 'Cu Petic MCX 10', tiers: { Essential: '35 LEI' } },
    ],
  },
  {
    name: 'Reparație Cameră',
    features: [
      { idd: 'camera_pp3', name: 'Cu Petic PP3-PP8', tiers: { Essential: '10 LEI' } },
      { idd: 'camera_pp9', name: 'Cu Petic PP9', tiers: { Essential: '15 LEI' } },
      { idd: 'camera_pp10', name: 'Cu Petic PP10', tiers: { Essential: '20 LEI' } },
    ],
  },
  {
    name: 'Hotel Anvelope',
    features: [
      { idd: 'hotel_anvelope_sub18', name: '<18 inch', tiers: { Essential: '80 LEI' } },
      { idd: 'hotel_anvelope_peste18', name: '≥18 inch', tiers: { Essential: '100 LEI' } },
    ],
  },
  {
    name: 'Îndreptat Jante',
    features: [
      { idd: 'indreptat_aluminiu', name: 'Aluminiu', tiers: { Essential: '80 LEI' } },
      { idd: 'indreptat_otel', name: 'Oțel/Tablă', tiers: { Essential: '40 LEI' } },
    ],
  },
  {
    name: 'Alte Servicii',
    features: [
      { idd: 'montaj_runflat', name: 'Montaj Anvelopă Runflat', tiers: { Essential: '10 LEI' } },
      { idd: 'jantat_butelie', name: 'Jantat cu Butelie', tiers: { Essential: '5 LEI' } },
    ],
  },
]

export const social = [
  {
    name: 'Facebook',
    href: 'https://www.facebook.com/vulcanizare.alexbestdrive',
    icon: (props) => (
      <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
        <path
          fillRule="evenodd"
          d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
          clipRule="evenodd"
        />
      </svg>
    ),
  },
  {
    name: 'Instagram',
    href: 'https://www.instagram.com/vulcanizare.alexbestdrive/',
    icon: (props) => (
      <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
        <path
          fillRule="evenodd"
          d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
          clipRule="evenodd"
        />
      </svg>
    ),
  },
  {
    name: 'Google',
    href: 'https://g.page/r/Ca7GzrPplDp0EBA',
    icon: (props) => (
      <svg fill="currentColor" viewBox="0 0 30 30" {...props}>
        <path
          fillRule="evenodd"
          d="M 15.003906 3 C 8.3749062 3 3 8.373 3 15 C 3 21.627 8.3749062 27 15.003906 27 C 25.013906 27 27.269078 17.707 26.330078 13 L 25 13 L 22.732422 13 L 15 13 L 15 17 L 22.738281 17 C 21.848702 20.448251 18.725955 23 15 23 C 10.582 23 7 19.418 7 15 C 7 10.582 10.582 7 15 7 C 17.009 7 18.839141 7.74575 20.244141 8.96875 L 23.085938 6.1289062 C 20.951937 4.1849063 18.116906 3 15.003906 3 z"
          clipRule="evenodd"
        />
      </svg>
    ),
  }
]

export const blogPostsPreview = [
  {
    id: 'sfaturi-ingrijire-anvelope',
    title: 'Sfaturi pentru îngrijirea anvelopelor',
    href: '/blog/sfaturi-ingrijire-anvelope',
    date: '16 Martie, 2022',
    category: { name: 'Articol'},
    imageUrl: <img
      className="h-48 w-96  object-cover"
      src={require('../../assets/vulcanizare-alex-best-drive-sfaturi-ingrijire-janta.jpg')}
      alt="*"
    />,
    preview:
      'Află sfaturi despre cum poți să îți păstrezi anvelopele în cea mai bună formă pentru mai mult timp.',
    author: {
      name: 'Alexandru Buraciuc',
    },
    readingLength: '3 min',
  },
  {
    id: 'locatie-vulcanizare-cisnadie',
    title: 'Cum ajungi la vulcanizarea ALEX BEST DRIVE din Cisnadie?',
    href: '/blog/locatie-vulcanizare-cisnadie',
    date: '3 Mai, 2022',
    category: { name: 'Articol'},
    imageUrl: <img
      className="h-48 w-96  object-cover"
      src={require('../../assets/locatie-vulcanizare-cisnadie-blog.png')}
      alt="Headline blog locatie vulcanizare cisnadie"
    />,
    preview:
      'În acest articol îți vom prezenta locația exactă unde se află vulcanizarea ALEX BEST DRIVE din Cisnadie, cât și cele mai rapide trasee pe care le poți urma pentru a ajunge la noi în funcție de zona în care locuiești.',
    author: {
      name: 'Alexandru Buraciuc',
    },
    readingLength: '3 min',
  },
]

export const blogPosts = [
  {
    id: 'sfaturi-ingrijire-anvelope',
    title: 'Sfaturi pentru îngrijirea anvelopelor',
    href: '/blog/sfaturi-ingrijire-anvelope',
    date: '16 Martie, 2022',
    datetime: '2022-03-16',
    category: { name: 'Articol'},
    imageUrl: <img
      className="h-48 w-96  object-cover"
      src={require('../../assets/vulcanizare-alex-best-drive-sfaturi-ingrijire-janta.jpg')}
      alt="*"
    />,
    preview:
      'Află sfaturi despre cum poți să îți păstrezi anvelopele în cea mai bună formă pentru mai mult timp.',
    author: {
      name: 'Alexandru Buraciuc',
      imageUrl:
        'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80'
    },
    readingLength: '3 min'
  },
  {
    id: 'locatie-vulcanizare-cisnadie',
    title: 'Cum ajungi la vulcanizarea ALEX BEST DRIVE din Cisnadie?',
    href: '/blog/locatie-vulcanizare-cisnadie',
    date: '3 Mai, 2022',
    datetime: '2022-05-03',
    category: { name: 'Articol'},
    imageUrl: <img
      className="h-48 w-96  object-cover"
      src={require('../../assets/locatie-vulcanizare-cisnadie-blog.png')}
      alt="Headline blog locatie vulcanizare cisnadie"
    />,
    preview:
      'În acest articol îți vom prezenta locația exactă unde se află vulcanizarea ALEX BEST DRIVE din Cisnadie, cât și cele mai rapide trasee pe care le poți urma pentru a ajunge la noi în funcție de zona în care locuiești.',
    author: {
      name: 'Alexandru Buraciuc',
      imageUrl:
        'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80'
    },
    readingLength: '5 min'
  }
]

