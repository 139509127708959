import { useParams } from 'react-router-dom';
import IncarcareAC from './Services/IncarcareAC';


const ServicesDetails = () => {
    const { id } = useParams();
    return ( 
        <div className="services-details">
            {id === 'incarcare-aer-conditionat-freon' && <IncarcareAC/>}
        </div>
     );
}

export default ServicesDetails;