import Navbar from './components/Navbar'
import Footer from './components/Footer';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import Pricing from './components/Pricing';
import Services from './components/Services';
import Contact from './components/Contact';
import Blog from './components/Blog';
import NotFound from './components/NotFound';
import BlogDetails from './components/BlogDetails'
import ServicesDetails from './components/ServicesDetails'




export default function Example() {
  return (
    <div className="bg-white">
      <div className="relative overflow-hidden">
        <BrowserRouter>
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/servicii" element={<Services />} />
            <Route path="/servicii/:id" element={<ServicesDetails />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/preturi" element={<Pricing />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/blog/:id" element={<BlogDetails />} />
            <Route path="/404" element={<NotFound />}/>
            <Route path="*" element={<NotFound />}/>
          </Routes>
        </BrowserRouter>
        <Footer />
      </div>
    </div>
  )
}