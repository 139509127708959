import React from "react";
import { MailIcon, PhoneIcon, CalendarIcon } from '@heroicons/react/outline'
import MetaTags from 'react-meta-tags';

export default function Contact() {
  return (
    <div className="relative bg-white">
      <MetaTags>
        <title>Contact Vulcanizare | Vulcanizare ALEX BEST DRIVE din Sibiu, Cisnadie</title>
        <link rel="canonical" href="https://www.vulcanizare-alexbestdrive.ro/contact" />
        <meta name="description" content="Servicii vulcanizare premium: echilibrare, dejantare, reparații anvelope, pene, schimb ulei și multe altele." />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta charset="UTF-8" />
      </MetaTags>
      <div className="absolute inset-0">
        <div className="absolute inset-y-0 left-0 w-1/2 bg-gray-50" />
      </div>
      <div className="relative max-w-7xl mx-auto lg:grid lg:grid-cols-5 h-540px">
        <div className="bg-gray-50 py-8 sm:py-16 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
          <div className="max-w-lg mx-auto">
            <h1 className="font-extrabold tracking-tight text-gray-900 text-4xl">Contact</h1>
            <h3 className="mt-3 text-lg leading-6 text-gray-500">
              Vulcanizarea este situată la câteva minute de Sibiu, la intrarea în orașul Cisnadie. Ca și reper pentru localnici, zona fostei mori Panalim.
            </h3>
            <dl className="mt-8 text-base text-gray-500">
              <div>
                <dt className="sr-only">Postal address</dt>
                <dd>
                  <p>Str. Șelimbărului, nr.32</p>
                  <p>Cisnadie, jud.Sibiu, 555300</p>
                </dd>
              </div>
              <div className="mt-6">
                <dt className="sr-only">Telefon</dt>
                <dd className="flex">
                  <PhoneIcon className="flex-shrink-0 h-6 w-6 text-gray-400" aria-hidden="true" />
                  <a href="tel:+40757132016" className="ml-3">+40 757 132 016</a>
                </dd>
              </div>
              <div className="mt-3">
                <dt className="sr-only">Email</dt>
                <dd className="flex">
                  <MailIcon className="flex-shrink-0 h-6 w-6 text-gray-400" aria-hidden="true" />
                  <a href="mailto: contact@alexbestdrive.ro" className="ml-3">contact@alexbestdrive.ro</a>
                </dd>
              </div>
              <div className="mt-3">
                <dt className="sr-only">Program</dt>
                <dd className="flex">
                  <CalendarIcon className="flex-shrink-0 h-6 w-6 text-gray-400" aria-hidden="true" />
                  <p className="ml-3">L-V: 8-17  S: 8-13  D: INCHIS
                  </p>
                </dd>
              </div>
            </dl>
          </div>
        </div>
        <div className="bg-white py-8  px-4 sm:px-6 lg:col-span-3 lg:px-8 xl:pl-12 pt-24">
          <iframe scrolling="no" title="Formular contact Vulcanizare Alex Best Drive" className="w-full h-470" src="https://notionforms.io/forms/programeaza-te"></iframe>
            {/* <form name="cereriProgramare" netlify netlify-honeypot="bot-field" method="POST" className="grid grid-cols-1 gap-y-6">
              <input type="hidden" name="form-name" value="cereriProgramare" />
              <div>
                <label htmlFor="full-name" className="sr-only">
                  Full name
                </label>
                <input
                  type="text"
                  name="full-name"
                  id="full-name"
                  autoComplete="name"
                  className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                  placeholder="Nume"
                />
              </div>
              <div>
                <label htmlFor="email" className="sr-only">
                  Email
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                  placeholder="Email (opțional)"
                />
              </div>
              <div>
                <label htmlFor="phone" className="sr-only">
                  Phone
                </label>
                <input
                  type="text"
                  name="phone"
                  id="phone"
                  autoComplete="tel"
                  className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                  placeholder="Telefon"
                />
              </div>
              <div>
                <label htmlFor="message" className="sr-only">
                  Mesaj
                </label>
                <textarea
                  id="message"
                  name="message"
                  rows={4}
                  className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border border-gray-300 rounded-md"
                  placeholder="Salut, ai un loc liber Miercuri pe la 14:00?"
                  defaultValue={''}
                />
              </div>
              <div>
                <button
                  type="submit"
                  className="inline-flex justify-center py-3 px-6 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-rose-600 hover:bg-rose-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Trimite
                </button>
              </div>
            </form> */}
        </div>
      </div>
    </div>
  )
}
